import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { CityTaxAmountPipe } from '../pipes/city-tax-amount.pipe';
import { InvoiceDetails } from '@app/models';
import { CurrencyFormatComponent } from '@app/ui';
import { FormatDateModule } from '@app/pipes';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'by-credit-note-info-summary',
  standalone: true,
  imports: [
    CommonModule,
    NzGridModule,
    NzAlertModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    CityTaxAmountPipe,
    CurrencyFormatComponent,
    FormatDateModule,
    RouterModule,
  ],
  templateUrl: './credit-note-info-summary.component.html',
  styleUrl: './credit-note-info-summary.component.scss',
})
export class CreditNoteInfoSummaryComponent {
  @Input({ required: true }) invoice: InvoiceDetails;

  get creditNotePartialEmitted() {
    return this.invoice?.linked_documents;
  }
}
