<div nz-row class="summary-info">
  <div nz-col [nzSpan]="24" class="title-summary">
    {{ 'summary_partial_credit_note_title' | translate | upperfirst }}
  </div>

  @if (invoice?.bills_list | cityTaxAmount) {
    <div nz-col [nzSpan]="24" class="row-summary">
      <div nz-row>
        <div nz-col [nzSpan]="14">
          {{ 'city_tax' | translate | upperfirst }}:
        </div>
        <div nz-col [nzSpan]="10" class="by-flex by-flex-end">
          <strong>
            <by-currency-format
              [currencyValue]="invoice?.bills_list | cityTaxAmount"
            ></by-currency-format>
          </strong>
        </div>
      </div>
    </div>
  }
  @if (+invoice?.amount_virtual_stamp) {
    <div nz-col [nzSpan]="24" class="row-summary">
      <div nz-row>
        <div nz-col [nzSpan]="14">
          {{ 'virtual_stamp' | translate | upperfirst }}:
        </div>
        <div nz-col [nzSpan]="10" class="by-flex by-flex-end">
          <strong>
            <by-currency-format
              [currencyValue]="+invoice?.amount_virtual_stamp"
            >
            </by-currency-format>
          </strong>
        </div>
      </div>
    </div>
  }

  @if (creditNotePartialEmitted?.length) {
    <div nz-col [nzSpan]="24" class="by-pl-2 by-pt-5 bolder by-fs-14">
      {{ 'partial_credit_notes' | translate | upperfirst }}:
    </div>

    <div nz-col [nzSpan]="24" class="credit-notes-box">
      <ul nz-col [nzSpan]="24">
        @for (note of creditNotePartialEmitted; track note.id) {
          <li class="by-pt-10">
            {{
              'number_of_date'
                | translate
                  : {
                      number: note?.number_complete,
                      date: note?.created_at | formatDate: 'L'
                    }
                | upperfirst
            }}

            {{ 'of' | translate }}
            <strong>
              <by-currency-format [currencyValue]="+note?.total">
              </by-currency-format
            ></strong>
            <a
              [routerLink]="['/reservation', invoice?.reservation_id]"
              [queryParams]="{ subTab: 3, invoiceId: note?.id }"
              target="blank"
            >
              <i class="fas fa-external-link color--azure pointer by-pl-1"></i
            ></a>
          </li>
        }
      </ul>
    </div>
  }
  <div nz-col [nzSpan]="24" class="by-p-10">
    <nz-alert
      class="alert-size"
      nzType="warning"
      [nzMessage]="'info_city_tax_remove_credit_note' | translate | upperfirst"
    ></nz-alert>
  </div>
</div>
