import { Component, forwardRef, signal, Input, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzFormModule } from 'ng-zorro-antd/form';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { CurrencyFormatComponent } from '@app/ui';
import { roundToTwoDecimals } from '@app/helpers/round-to-decimals';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { InvoiceDetails } from '@app/models';
import { RouterModule } from '@angular/router';
import { CityTaxAmountPipe } from '../pipes/city-tax-amount.pipe';
import { FormatDateModule } from '@app/pipes';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CreditNoteInfoSummaryComponent } from '../credit-note-info-summary/credit-note-info-summary.component';

@Component({
  selector: 'by-credit-note-register-slider',
  standalone: true,
  imports: [
    CommonModule,
    NzSliderModule,
    NzInputNumberModule,
    ReactiveFormsModule,
    FormsModule,
    NzFormModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NzGridModule,
    CurrencyFormatComponent,
    NzPopoverModule,
    RouterModule,
    CityTaxAmountPipe,
    FormatDateModule,
    NzAlertModule,
    NzToolTipModule,
    CreditNoteInfoSummaryComponent,
  ],
  templateUrl: './credit-note-register-slider.component.html',
  styleUrl: './credit-note-register-slider.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditNoteRegisterSliderComponent),
      multi: true,
    },
  ],
})
export class CreditNoteRegisterSliderComponent implements ControlValueAccessor {
  @Input({ required: true }) maxValue: number;

  @Input({ required: true }) invoice: InvoiceDetails;

  value = signal<number>(0);

  percentage = computed(() =>
    roundToTwoDecimals((this.value() * 100) / this.maxValue, 3),
  );

  onChange: (value: number) => void = () => {};

  onTouched: () => void = () => {};

  writeValue(value: number): void {
    this.value.set(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleValueChange(value: number): void {
    this.value.set(value);
    this.onChange(value);
  }

  handlePercentageChange(value: number): void {
    this.value.set(roundToTwoDecimals((this.maxValue * value) / 100, 3));

    this.onChange(this.value());
  }

  get creditNotePartialEmitted() {
    return this.invoice.linked_documents;
  }

  get isAdvanceAttachedAndMaxRefundMoreTotalInvoice() {
    return (
      !!this.invoice?.advances?.list?.length &&
      this.invoice.maxRefundable > this.invoice.total
    );
  }

  get isAdvanceInvoice() {
    return !!this.invoice.advance;
  }
}
