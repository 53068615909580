<nz-form-item>
  <nz-form-label [nzSpan]="24">
    <strong>{{ 'select_cancel_import' | translate | upperfirst }}</strong>
  </nz-form-label>
  <nz-form-control>
    <nz-slider
      (blur)="onTouched()"
      [nzMin]="1"
      [nzMax]="maxValue"
      [ngModel]="value()"
      (nzOnAfterChange)="handleValueChange($event)"
    ></nz-slider>
  </nz-form-control>
</nz-form-item>
<div nz-row>
  <div nz-col [nzSpan]="14" class="by-flex by-gap-5">
    <nz-form-item>
      <nz-form-control>
        <nz-input-number
          (blur)="onTouched()"
          [nzMin]="0.01"
          [nzMax]="maxValue"
          [nzStep]="1"
          [ngModel]="value()"
          (ngModelChange)="handleValueChange($event)"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
    <div class="by-pt-5">
      <span>{{ 'of' | translate }}</span>

      <by-currency-format [currencyValue]="maxValue"> </by-currency-format>

      @if (isAdvanceInvoice) {
        <i
          class="far fa-info-circle color--orange by-pl-5 pointer"
          nz-tooltip
          [nzTooltipTitle]="
            'credit_note_info_tooltip_advance_amount' | translate | upperfirst
          "
        ></i>
      }

      @if (isAdvanceAttachedAndMaxRefundMoreTotalInvoice) {
        <i
          class="far fa-info-circle color--orange by-pl-5 pointer"
          nz-tooltip
          [nzTooltipTitle]="
            'credit_note_tooltip_amount_more_invoice_for_advance'
              | translate
              | upperfirst
          "
        ></i>
      }

      @if (
        !isAdvanceInvoice &&
        !isAdvanceAttachedAndMaxRefundMoreTotalInvoice &&
        (+invoice?.amount_virtual_stamp ||
          (invoice?.bills_list | cityTaxAmount) ||
          creditNotePartialEmitted?.length)
      ) {
        <i
          class="far fa-info-circle color--orange by-pl-5 pointer"
          nz-popover
          [nzPopoverContent]="summaryTemplate"
          nzPopoverPlacement="bottom"
          nzPopoverTrigger="hover"
          nzPopoverOverlayClassName="price-details-popover__container"
        ></i>
      }
    </div>
  </div>
  <div nz-col [nzSpan]="10" class="by-flex by-flex-end by-gap-5">
    <div class="by-pt-5">%</div>
    <nz-form-item>
      <nz-form-control>
        <nz-input-number
          (blur)="onTouched()"
          [nzMin]="0.01"
          [nzMax]="100"
          [nzStep]="0.5"
          [ngModel]="percentage()"
          (ngModelChange)="handlePercentageChange($event)"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>

<ng-template #summaryTemplate>
  <by-credit-note-info-summary
    [invoice]="invoice"
  ></by-credit-note-info-summary>
</ng-template>
